/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {MapInfo} = _components;
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "ototoxic-drugs-drugs-that-cause-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ototoxic-drugs-drugs-that-cause-hearing-loss",
    "aria-label": "ototoxic drugs drugs that cause hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ototoxic Drugs: Drugs that Cause Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Problems with your hearing can happen at any age. Senior citizens aren’t the only ones dealing with this; in fact, one in six Americans are ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "experiencing hearing loss"), ". Is it possible that you have hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss happens when any part of the ear or auditory system becomes damaged or is not working properly. It can be permanent or temporary, and can happen suddenly or gradually. The different ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/degrees-of-hearing-loss/",
    className: "c-md-a"
  }, "degrees of hearing loss"), " are known as Mild, Moderate, Severe and Profound. Each one is highly impactful and should be treated by a professional. You don’t have to suffer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-hearing-loss",
    "aria-label": "causes of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss can be caused by many different factors. Factors include ear infections, excess fluid, a hole in the eardrum, or earwax buildup. You could have damage from attending too many loud concerts without proper ear protection or repeated exposure to loud noises."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Did you know? Noise-induced hearing loss is one of the most common forms of hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ototoxic-drugs-and-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ototoxic-drugs-and-hearing-loss",
    "aria-label": "ototoxic drugs and hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ototoxic drugs and hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some medications can also lead to hearing loss. Drugs that harm the ear and cause hearing loss are considered ototoxic. Ototoxicity is a risk for people taking certain medications on a regular basis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These ", React.createElement("a", {
    href: "https://www.asha.org/public/hearing/ototoxic-medications/",
    target: "_blank"
  }, "ototoxic medications"), " damage the sensory cells required to hear and to balance. These sensory cells are in the inner ear and when the cochlea in the inner ear is damaged, your hearing ability suffers. This damage is quite detrimental to your hearing health and should not be taken lightly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss by ototoxic medicine can develop quickly. The first symptoms are typically ringing in the ears (tinnitus) and vertigo. It’s uncommon for hearing to return to normal after you stop taking the medication. However, hearing does tend to return to normal if you have a reaction to Asprin and then stop taking it. It is very important to speak with your doctor about the potential risks, especially if you’re on more than one medication already."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Common medications with a risk of ototoxicity are:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Aspirin in large doses"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Nonsteroidal anti-inflammatory drugs (NSAIDs) like ibuprofen and naproxen"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Antibiotics and aminoglycosides (gentamicin, streptomycin, and neomycin)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Loop diuretics like furosemide (Lasix) and bumetanide"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Cancer drugs like cyclophosphamide, cisplatin, bleomycin ", React.createElement("a", {
    href: "https://www.uofmhealth.org/health-library/tf3092",
    target: "_blank"
  }, "and others")), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "ASHA reports there are over 200 ototoxic medications on the market today. These include both prescription and over-the-counter drugs. The medicines are used to treat infections, malaria, cancer, pain, kidney conditions, and heart disease."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The medications that can cause permanent damage are aminoglycoside antibiotics and chemotherapy drugs (cisplatin and carboplatin). Medications that can lead to temporary hearing loss include: salicylate pain relievers, quinine, and loop diuretics. Sometimes, exposure to loud noises while taking the drugs can increase the risk of damage."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The pros of a life-saving medication may outweigh the cons of hearing loss. Your individual health goals should be discussed with your healthcare team. You should monitor your hearing, balance, and symptoms while taking an ototoxic drug. You should also have periodic hearing tests. If you want to do something about your hearing loss issues, then find your state below:"), "\n", React.createElement(MapInfo));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
